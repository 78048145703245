import React from "react";

import Layout from "../layouts";
import SEO from "../components/seo";

import keywords from "../data/keywords.json";

function ContactPage() {
  return (
    <Layout showWaitlist={false}>
      <SEO keywords={keywords} title="Contact" />
      <section className="w-full flex items-center justify-center p-4">
        <div className="w-full max-w-6xl flex flex-col sm:flex-row justify-center items-start space-x-4">
          <div className="w-full sm:w-1/2 flex flex-col items-start justify-center space-y-3">
            <div className="py-4 flex flex-col space-y-5">
              <p className="text-2xl">We will be happy to reply to any of your queries</p>
              <span className="text-xl">
                Email:{" "}
                <a
                  className="text-mph-orange"
                  href="mailto:contact@myproducthub.com"
                >
                  contact@myproducthub.com
                </a>
              </span>
              <span className="text-xl">
                Support Email:{" "}
                <a
                  className="text-mph-orange"
                  href="mailto:support@myproducthub.com"
                >
                  support@myproducthub.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
